const PrivacyPolicyList = [
	{
		icon: "mdi:account-details-outline",
		title: "Data Collection",
		paragraph:
			"We collect and process your data when you sign up for our services, request waste management solutions, or contact us for support. The types of data we collect may include your name, address, contact information, and service usage details. This information helps us to improve our services and ensure efficient waste management operations. All data collection complies with local data protection laws, and we will always obtain your consent before collecting any sensitive information.",
	},
	{
		icon: "mdi:share-all-outline",
		title: "Data Sharing",
		paragraph:
			"We share your data strictly on a need-to-know basis and only with your consent. Data sharing may occur with third-party service providers who assist us in delivering waste management services. All service providers are required to maintain the confidentiality and security of your data. We do not sell or rent your personal data to third parties for marketing purposes without your explicit consent.",
	},
	{
		icon: "mdi:shield-lock-outline",
		title: "Data Security",
		paragraph:
			"Protecting your personal data and waste data is our top priority. We implement stringent security measures to safeguard your personal information from unauthorized access, alteration, or disclosure. However, it is also important for you to exercise caution when sharing your data, especially on public networks. While we strive to protect your data, we cannot be held responsible for any breaches that occur due to your actions.",
	},
	{
		icon: "mdi:refresh",
		title: "Policy Updates",
		paragraph:
			"We may update this Privacy Policy periodically to reflect changes in our practices or for other operational, legal, or regulatory reasons. All updates will be posted on this page. Your continued use of our services after any changes indicates your acceptance of the revised terms. We encourage you to review this policy regularly to stay informed about how we protect your personal information.",
	},
];

export default PrivacyPolicyList;